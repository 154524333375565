import React, { useEffect, useState } from "react";
import LayoutMy from "../LayoutMy/LayoutMy";
import "./MyMainContent.scss";
import RechargeBank from "../Recharge/RechargeBank";
import AddBanking from "../AddBanking/AddBanking";
import Withdraw from "../Withdraw/Withdraw";
import HistoryWithdraw from "../Withdraw/HistoryWithdraw";
import HistoryRecharge from "../Recharge/HistoryRecharge";
import InfoMy from "../InfoMy/InfoMy";
import ChangePW from "../InfoMy/ChangePW";
import ChangePWTrans from "../InfoMy/ChangePWTrans";
import Tranfer from "../Tranfer/Tranfer";
import HistoryTrans from "../Tranfer/HistoryTrans";
import Wallet from "../MoneyFund/Wallet";
import Reward from "../MoneyFund/Reward";
import OttherHistory from "../MoneyFund/OttherHistory";
import FeedBack from "../FeedBack/FeedBack";
import HistoryFeedBack from "../FeedBack/HistoryFeedback";
import BetHistoryByGame from "../BetHistory/BetHistoryByGame";
import { listLichSuCuoc } from "../../dummy";

function MyMainContent(props) {
  const [custom, setCustom] = useState(0);
  const [component, setComponent] = useState();

  const callbackFunction = (i) => {
    setCustom(i);
  };

  const componentMap = new Map([
    ["recharge", [RechargeBank, HistoryRecharge]],
    ["withdraw", [Withdraw, HistoryWithdraw, AddBanking]],
    ["info", [InfoMy, ChangePW, ChangePWTrans]],
    ["transfer", [Tranfer, HistoryTrans]],
    [
      "money",
      [HistoryRecharge, HistoryWithdraw, Wallet, Reward, OttherHistory],
    ],
    ["feedback", [FeedBack, HistoryFeedBack]],
  ]);

  useEffect(() => {
    if (props.params !== "history") {
      const Component = componentMap.get(props.params)[custom];
      setComponent(<Component />);
    }
  }, [props.params, custom]);

  return (
    <main className="el-main app-main">
      <LayoutMy content={props.content} parentCallback={callbackFunction} />
      {props.params === "history" ? (
        <BetHistoryByGame title={listLichSuCuoc[custom].title} />
      ) : (
        component
      )}
    </main>
  );
}

export default MyMainContent;
