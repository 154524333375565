import React, { useEffect, useState } from "react";
import {
  iconNhanHieu,
  iconTaiXuong,
  iconHopTac,
  iconPhoBien,
  iconTrangMang,
  iconDichVu,
  iconDocQuyen,
  iconNguoiMoi,
  iconVietNam,
  iconNguoiDungOnline,
} from "../../../../img/importImg";

function CenterHeader() {
  const [nowDay, setNowDay] = useState();
  const [nowTime, setNowTime] = useState();
  const newDate = new Date();

  function formatDate(date) {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `Ngày ${day} Tháng ${month} Năm ${year}`;
  }

  function formatTime(date) {
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");

    return `${hours}:${minutes}`;
  }

  useEffect(() => {
    setNowDay(formatDate(newDate));
    setNowTime(formatTime(newDate));
  }, []);

  useEffect(() => {
    setInterval(() => {
      setNowDay(formatDate(newDate));
      setNowTime(formatTime(newDate));
    }, 60000);
  }, []);

  return (
    <div className="center-header">
      <div className="container">
        <ul>
          <li>
            <a href="/about">
              <span className="title">
                <img src={iconNhanHieu} alt="" />
                Nhãn hiệu
              </span>
              Giới Thiệu
            </a>
            <a href="/">
              <span className="title">
                <img src={iconTaiXuong} alt="" />
                Tải xuống
              </span>
              Ứng Dụng Di Động
            </a>
          </li>
          <li>
            <a href="/">
              <span className="title">
                <img src={iconHopTac} alt="" />
                Hợp tác
              </span>
              Đại Lý
            </a>
            <a href="/">
              <span className="title">
                <img src={iconPhoBien} alt="" />
                Phổ biến
              </span>
              Trò Chơi Xổ Số
            </a>
          </li>
          <li>
            <a href="/">
              <span className="title">
                <img src={iconTrangMang} alt="" />
                Trang mạng
              </span>
              Link Dự Phòng
            </a>
            <a href="/">
              <span className="title">
                <img src={iconDichVu} alt="" />
                Dịch vụ
              </span>
              Chăm sóc khách hàng
            </a>
          </li>
          <li>
            <a href="/">
              <span className="title">
                <img src={iconDocQuyen} alt="" />
                Độc quyền
              </span>
              Xổ Số Trực Tiếp
            </a>
            <a href="/">
              <span className="title">
                <img src={iconNguoiMoi} alt="" />
                Người mới
              </span>
              Tài khoản dùng thử
            </a>
          </li>
          <li>
            <a href="/">
              <span className="title">Giờ Hà Nội</span>
              <span className="day">{nowDay}</span>
              <span className="hour" style={{ marginBottom: "0.5px" }}>
                {nowTime}
              </span>
              <span className="country">
                <img src={iconVietNam} alt="" />
              </span>
            </a>
            <a href="/">
              <span className="title">Người dùng trực tuyến</span>
              <div className="count-online">
                <img src={iconNguoiDungOnline} alt="" />
                <div className="box-count">
                  <span>3</span>
                  <span>0</span>
                  <span>6</span>
                  <span>7</span>
                  <span>9</span>
                  <span>6</span>
                </div>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default CenterHeader;
