import React from "react";
import { Link } from "react-router-dom";

const data = {
  mienbac: ["Miền Bắc"],
  trochoi: ["Tài Xỉu"],
  sieutoc: [
    { title: "Siêu Tốc 1 Phút", link: "/xo-so/1" },
    { title: "Siêu Tốc 3 Phút", link: "/xo-so/3" },
    { title: "Siêu Tốc 5 Phút", link: "/xo-so/5" },
    { title: "Siêu Tốc 10 Phút", link: "/xo-so/10" },
    { title: "Siêu Tốc 15 Phút", link: "/xo-so/15" },
    { title: "Siêu Tốc 20 Phút", link: "/xo-so/20" },
    { title: "Siêu Tốc 30 Phút", link: "/xo-so/30" },
  ],
  mega645: ["Mega 6/45 1 Giây", "Mega 6/45 1 Phút"],
  xosoVIP: ["Hà Nội VIP", "Hồ Chí Minh VIP"],
  miennam: ["An Giang", "Bình Thuận", "Tây Ninh", "M.Nam Siêu Tốc 45 Giây"],
  mientrung: ["Quảng Trị", "Quảng Bình", "Bình Định", "M.Trung 45 Giây"],
  thailan: ["1 Phút Thái Lan", "2 Phút Thái Lan", "3 Phút Thái Lan"],
};

function NavbarXS() {
  return (
    <ul className="sub-text">
      {data.mienbac && (
        <li>
          <div className="head">
            <h3 className="title">Miền Bắc</h3>
            <select name="xosomienbac" id="xosomienbac">
              <option value="toanbo">Toàn bộ</option>
              <option value="chunhat">Chủ nhật</option>
              <option value="thuhai">Thứ hai</option>
              <option value="thuba">Thứ ba</option>
              <option value="thutu">Thứ tư</option>
              <option value="thunam">Thứ năm</option>
              <option value="thusau">Thứ sáu</option>
              <option value="thubay">Thứ bảy</option>
            </select>
          </div>
          <ul>
            {data.mienbac.map((item, i) => {
              return (
                <li key={i}>
                  <Link to="/xo-so/xsmb">
                    <span>{item}</span>
                  </Link>
                </li>
              );
            })}
          </ul>
        </li>
      )}

      {data.trochoi && (
        <li>
          <div className="head">
            <h3 className="title">Trò Chơi</h3>
          </div>
          <ul>
            {data.trochoi.map((item, i) => {
              return (
                <li key={i}>
                  <Link to="/game-tai-xiu">
                    <span>{item}</span>
                  </Link>
                </li>
              );
            })}
          </ul>
        </li>
      )}

      {data.sieutoc && (
        <li>
          <div className="head">
            <h3 className="title">Siêu Tốc</h3>
          </div>
          <ul>
            {data.sieutoc.map((item, i) => {
              return (
                <li key={i}>
                  <Link to={item.link}>
                    <span>{item.title}</span>
                  </Link>
                </li>
              );
            })}
          </ul>
        </li>
      )}

      {data.mega645 && (
        <li>
          <div className="head">
            <h3 className="title">Mega 6/45</h3>
          </div>
          <ul>
            {data.mega645.map((item, i) => {
              return (
                <li key={i}>
                  <a href="/">
                    <span>{item}</span>
                  </a>
                </li>
              );
            })}
          </ul>
        </li>
      )}

      {data.xosoVIP && (
        <li>
          <div className="head">
            <h3 className="title">Xổ số VIP</h3>
          </div>
          <ul>
            {data.xosoVIP.map((item, i) => {
              return (
                <li key={i}>
                  <a href="/">
                    <span>{item}</span>
                  </a>
                </li>
              );
            })}
          </ul>
        </li>
      )}

      {data.miennam && (
        <li>
          <div className="head">
            <h3 className="title">Miền Nam</h3>
            <select name="xosomientrung" id="xosomientrung">
              <option value="toanbo">Toàn bộ</option>
              <option value="chunhat">Chủ nhật</option>
              <option value="thuhai">Thứ hai</option>
              <option value="thuba">Thứ ba</option>
              <option value="thutu">Thứ tư</option>
              <option value="thunam">Thứ năm</option>
              <option value="thusau">Thứ sáu</option>
              <option value="thubay">Thứ bảy</option>
            </select>
          </div>
          <ul>
            {data.miennam.map((item, i) => {
              return (
                <li key={i}>
                  <a href="/">
                    <span>{item}</span>
                  </a>
                </li>
              );
            })}
          </ul>
        </li>
      )}

      {data.mientrung && (
        <li>
          <div className="head">
            <h3 className="title">Miền Trung</h3>
            <select name="xosomientrung" id="xosomientrung">
              <option value="toanbo">Toàn bộ</option>
              <option value="chunhat">Chủ nhật</option>
              <option value="thuhai">Thứ hai</option>
              <option value="thuba">Thứ ba</option>
              <option value="thutu">Thứ tư</option>
              <option value="thunam">Thứ năm</option>
              <option value="thusau">Thứ sáu</option>
              <option value="thubay">Thứ bảy</option>
            </select>
          </div>
          <ul>
            {data.mientrung.map((item, i) => {
              return (
                <li key={i}>
                  <a href="/">
                    <span>{item}</span>
                  </a>
                </li>
              );
            })}
          </ul>
        </li>
      )}

      {data.thailan && (
        <li>
          <div className="head">
            <h3 className="title">Thái Lan</h3>
          </div>
          <ul>
            {data.thailan.map((item, i) => {
              return (
                <li key={i}>
                  <a href="/">
                    <span>{item}</span>
                  </a>
                </li>
              );
            })}
          </ul>
        </li>
      )}
    </ul>
  );
}

export default NavbarXS;
