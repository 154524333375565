import React, { useEffect, useState } from "react";
import {
  iconPhone,
  iconEmail,
  iconFooterWork,
  iconSecurity,
  iconService,
  pays,
} from "../../../img/importImg";
import withdrawAPI from "../../../API/withdrawAPI";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

function Footer() {
  const [settings, setSettings] = useState();

  useEffect(() => {
    try {
      const fetchGetSetting = async () => {
        const result = await withdrawAPI.getSetting();
        // console.log(result.data[0]);

        if (result.status === true) {
          setSettings(result.data[0]);
        } else {
          return toast.error("Có lỗi xảy ra!", { theme: "light" });
        }
      };

      fetchGetSetting();
    } catch (error) {
      toast.error(error, { theme: "light" });
    }
  }, []);

  return (
    <footer>
      <div className="ft-top">
        <div className="container">
          <ul>
            <li>
              <a href="/">
                <img src={iconPhone} alt="" />
                <p>
                  Hotline: {settings && <span>{settings.phone_number}</span>}
                </p>
              </a>
            </li>
            <li>
              <a href="/">
                <img src={iconEmail} alt="" />
                <p>{settings && settings.email}</p>
              </a>
            </li>
            <li>
              <a href="/">
                <img src={iconFooterWork} alt="" />
                <p>ĐẠI LÝ</p>
              </a>
            </li>
            <li>
              <a href="/">
                <img src={iconSecurity} alt="" />
                <p>Hướng dẫn chống hack DNS</p>
              </a>
            </li>
            <li>
              <Link to="/support">
                <img src={iconService} alt="" />
                <p>Chăm sóc khách hàng</p>
              </Link>
            </li>
          </ul>
        </div>
      </div>

      <div className="ft-center">
        <div className="container">
          <img src={pays} alt="" />
        </div>
      </div>

      <div className="ft-bottom">
        <div className="container">
          <div className="copyright">
            COPYRIGHT © {settings && settings.name_page} ALL RIGHTS RESERVED
          </div>
        </div>
      </div>

      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </footer>
  );
}

export default Footer;
