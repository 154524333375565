import React, { useState, useEffect } from "react";
import transferAPI from "../../API/transferAPI";
import accountAPI from "../../API/account";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Input, Button } from "reactstrap";
import { tranferIcon } from "../../img/importImg";
import ReactLoading from "react-loading";

const VND = new Intl.NumberFormat("vi-VN", {
  style: "currency",
  currency: "VND",
});

function Tranfer() {
  const [listBalance, setListBalance] = useState();
  const [listGame, setListGame] = useState();
  const [user, setUser] = useState();
  const [chuyenra, setchuyenra] = useState("TKND");
  const [chuyenvao, setchuyenvao] = useState("IB");
  const [isSwapped, setIsSwapped] = useState(false);
  const [money, setMoney] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [loadPage, setLoadPage] = useState(false);

  const handleTransfer = () => {
    if (!isSwapped) {
      const temp = chuyenra;
      setchuyenra(chuyenvao);
      setchuyenvao(temp);
    } else {
      const temp = chuyenvao;
      setchuyenvao(chuyenra);
      setchuyenra(temp);
    }
    setIsSwapped(!isSwapped);
  };

  useEffect(() => {
    try {
      const fetchGetBalance = async () => {
        setIsLoading(true);
        const result = await transferAPI.getBalance();
        const dataUser = await accountAPI.getUserInfo();
        //   console.log(result);
        //   console.log(dataUser);
        setUser(dataUser);
        if (result.status === true) {
          setListBalance(result.data);

          const listGameNew = result.data.map((item) => {
            return {
              providercode: item.providercode,
              name: item.name,
            };
          });

          // console.log(listGameNew);

          setListGame(listGameNew);
          setIsLoading(false);
        } else {
          return toast.error(result.msg, { theme: "light" });
        }
      };

      fetchGetBalance();
    } catch (error) {
      return toast.error("Có lỗi xảy ra!", { theme: "light" });
    }
  }, [loadPage]);

  const handleClickTrans = async () => {
    try {
      if (!money) {
        return toast.error("Không được để trống!", { theme: "light" });
      }

      if (Number(money) < 1000) {
        return toast.error("Số tiền chuyển tối thiểu là 1,000 VNĐ!", {
          theme: "light",
        });
      }

      if (chuyenra === "TKND" && chuyenvao === "TKND") {
        return toast.error(
          "Vui lòng chuyển trung gian qua tài khoản người dùng!",
          {
            theme: "light",
          }
        );
      }

      const providercode = chuyenvao === "TKND" ? chuyenra : chuyenvao;

      if (chuyenvao === "TKND") {
        setIsSwapped(true);
      }

      let typeNew = chuyenvao === "TKND" ? "1" : "0";

      // 0 là nạp vào api, 1 là rút từ api về
      const data = {
        amount: Number(money),
        type: typeNew,
        providercode: providercode,
      };

      // console.log(data);
      setIsLoading(true);
      const result = await transferAPI.postTrans(data);
      setLoadPage(!loadPage);
      //   console.log(result);
      if (result.result.status === true) {
        toast.success(result.result.msg, { theme: "light" });
      } else {
        toast.error(result.result.msg, { theme: "light" });
      }
    } catch (error) {
      return toast.error("Có lỗi xảy ra", { theme: "light" });
    }
  };

  return (
    <div className="d-flex">
      <div
        className="d-flex flex-column py-4 px-4 align-items-center justify-content-center w-50"
        style={{ borderRight: "1px solid #e7e7e7" }}
      >
        <div>
          <span>Chuyển ra: </span>
          <Input
            type="select"
            name="select"
            placeholder="Vui lòng nhập"
            className="ml-4"
            style={{
              height: "35px",
              borderColor: "#43a7fc",
              borderRadius: "5px",
              width: "200px",
            }}
            value={chuyenra}
            onChange={(e) => setchuyenra(e.target.value)}
          >
            <option value="TKND">Tài khoản người dùng</option>
            {listGame &&
              listGame.map((item, i) => {
                return (
                  <option key={i} value={item.providercode}>
                    {item.providercode} - {item.name}
                  </option>
                );
              })}
          </Input>
        </div>

        <img
          src={tranferIcon}
          alt=""
          style={{
            width: "28px",
            height: "28px",
            cursor: "pointer",
          }}
          className="my-4"
          onClick={handleTransfer}
        />

        <div className="pb-4">
          <span>Chuyển vào:</span>
          <Input
            type="select"
            name="select"
            style={{
              height: "35px",
              borderColor: "#43a7fc",
              borderRadius: "5px",
              width: "200px",
            }}
            className="ml-4"
            value={chuyenvao}
            onChange={(e) => setchuyenvao(e.target.value)}
          >
            <option value="TKND">Tài khoản người dùng</option>
            {listGame &&
              listGame.map((item, i) => {
                return (
                  <option key={i} value={item.providercode}>
                    {item.providercode} - {item.name}
                  </option>
                );
              })}
          </Input>
        </div>

        <div>
          <span>Số tiền chuyển tối đa hiện tại: {VND.format(user?.money)}</span>
        </div>

        <div className="text-center pt-4">
          <span>
            Để chuyển tiền qua lại giữa 2 game, vui lòng chuyển trung gian qua
            tài khoản người dùng!
          </span>
        </div>

        <div className="text-center pt-4">
          <span>Số tiền chuyển tối thiểu là 1,000 VNĐ</span>
        </div>

        <div className="d-flex pt-4 align-items-center">
          <span>Tiền: </span>
          <Input
            className=""
            placeholder="Vui lòng nhập"
            style={{
              height: "35px",
              borderColor: "#43a7fc",
              borderRadius: "5px",
              fontSize: "14px",
              width: "200px",
              marginLeft: "60px",
            }}
            type="number"
            onChange={(e) => setMoney(e.target.value)}
          ></Input>
        </div>

        <span
          className="pt-4"
          style={money ? { display: "block" } : { display: "none" }}
        >
          Bạn muốn chuyển: {VND.format(money)} ?
        </span>

        {isLoading ? (
          <ReactLoading type="bubbles" color="#ff8a00" height={10} width={50} />
        ) : (
          <Button
            color="primary"
            className="px-4 py-2 mt-4"
            style={{ width: "100px", fontSize: "14px", borderRadius: "5px" }}
            onClick={handleClickTrans}
          >
            Đồng ý
          </Button>
        )}
      </div>
      <div
        className="d-flex w-50 flex-column my-4 mx-4"
        style={{ border: "1px solid grey" }}
      >
        <div
          style={{
            backgroundColor: "#e7f0fc",
            borderBottom: "1px solid #43a7fc",
          }}
          className="pl-4"
        >
          <div className="d-flex align-item-center" style={{ height: "50px" }}>
            <span style={{ marginTop: "16px", paddingLeft: "10px" }}>
              Số dư tài khoản chính:{" "}
            </span>
            {isLoading ? (
              <div>
                <ReactLoading
                  type="bubbles"
                  color="#ff8a00"
                  height={10}
                  width={50}
                />
              </div>
            ) : (
              <span
                style={{
                  color: "#ff8a00",
                  marginTop: "16px",
                  paddingLeft: "10px",
                }}
              >
                {VND.format(user?.money)}
              </span>
            )}
          </div>
        </div>
        <div className="d-flex flex-wrap">
          {listBalance &&
            listBalance.map((item, i) => {
              return (
                <div
                  key={i}
                  className="d-flex flex-column w-25 justify-content-center align-items-center "
                  style={{
                    borderRight: "1px solid grey",
                    borderBottom: "1px solid grey",
                    height: "100px",
                  }}
                >
                  <span style={{ textAlign: "center" }}>
                    {item.providercode}-{item.name}
                  </span>

                  {isLoading ? (
                    <div>
                      <ReactLoading
                        type="bubbles"
                        color="#ff8a00"
                        height={10}
                        width={50}
                      />
                    </div>
                  ) : (
                    <span
                      style={{
                        color: "#ff8a00",
                        marginTop: "28px",
                      }}
                    >
                      {VND.format(item.balance)}
                    </span>
                  )}
                </div>
              );
            })}
        </div>
      </div>

      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default Tranfer;
