const VND = new Intl.NumberFormat("vi-VN", {
  style: "currency",
  currency: "VND",
});

function getTimeLeft() {
  const now = new Date();
  const target = new Date(now);
  target.setHours(18, 0, 0, 0);

  const timeDiff = target - now;
  const hours = Math.floor(timeDiff / (1000 * 60 * 60));
  const minutes = Math.floor((timeDiff / (1000 * 60)) % 60);
  const seconds = Math.floor((timeDiff / 1000) % 60);

  return {
    hours: [Math.floor(hours / 10), hours % 10],
    minutes: [Math.floor(minutes / 10), minutes % 10],
    seconds: [Math.floor(seconds / 10), seconds % 10],
  };
}

function getCurrentDate() {
  const today = new Date();
  const dd = String(today.getDate()).padStart(2, "0");
  const mm = String(today.getMonth() + 1).padStart(2, "0");
  const yyyy = today.getFullYear();

  return `${dd}/${mm}/${yyyy}`;
}

function formatSeconds(seconds) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  return {
    hours: [Math.floor(hours / 10), hours % 10],
    minutes: [Math.floor(minutes / 10), minutes % 10],
    seconds: [Math.floor(remainingSeconds / 10), remainingSeconds % 10],
  };
}

function isValidFormat(str, pattern) {
  const regex = new RegExp(pattern);
  if (!regex.test(str)) {
    return false;
  }
  const numbers = str.split(",");
  const uniqueNumbers = new Set(numbers);
  return numbers.length == uniqueNumbers.size;
}

function isValidFormatLoDe(str) {
  const pattern = /^(\d{2,},)*\d{2,}$/;
  if (!pattern.test(str)) {
    return false;
  }
  const nums = str.split(",");
  const set = new Set(nums);
  return set.size === nums.length;
}

function isValidFormatXien2(str) {
  const pattern = /^(\d{2}),(?!\1)(\d{2})$/;
  const match = str.match(pattern);
  if (!match) {
    return false;
  }
  const set = new Set(match.slice(1));
  return set.size === 2;
}

function isValidFormatXien3(str) {
  const pattern = /^(\d{2}),(?!\1)(\d{2}),(?!\1|\2)(\d{2})$/;
  const match = str.match(pattern);
  if (!match) {
    return false;
  }
  const set = new Set(match.slice(1));
  return set.size === 3;
}

function isValidFormatXien4(str) {
  const pattern =
    /^(\d{2}),(?!\1)(\d{2}),(?!\1|\2)(\d{2}),(?!\1|\2|\3)(\d{2})$/;
  const match = str.match(pattern);
  if (!match) {
    return false;
  }
  const set = new Set(match.slice(1));
  return set.size === 4;
}

function isValidFormatDauDuoi(str) {
  const regex = /^(\d,)*\d$/;
  if (!regex.test(str)) {
    return false;
  }
  const numbers = str.split(",");
  const uniqueNumbers = new Set(numbers);
  return numbers.length == uniqueNumbers.size;
}

function isValidFormat3Cang(str) {
  const regex = /^(\d{3},)*\d{3}$/;
  if (!regex.test(str)) {
    return false;
  }
  const numbers = str.split(",");
  const uniqueNumbers = new Set(numbers);
  return numbers.length == uniqueNumbers.size;
}

export { VND, getCurrentDate, getTimeLeft, formatSeconds, isValidFormat };
