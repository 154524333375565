import React, { useState, useEffect } from "react";
import Navbar from "../../Navbar/Navbar";
import pageAPI from "../../../../API/pageAPI";
import { baseUrl } from "../../../../dummy";

function TopHeader() {
  const [logo, setLogo] = useState();

  useEffect(() => {
    try {
      const fetchGetLogo = async () => {
        const result = await pageAPI.getLogo();
        // console.log(result);

        if (result.status === true) {
          setLogo(result.data[0].logo_image);
        }
      };

      fetchGetLogo();
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <div className="top-header">
      <div className="container container-width__style">
        <div className="content">
          <a href="/" className="logo">
            <img
              src={`${baseUrl + logo}`}
              alt="logo"
              style={{ width: "300px", height: "100px" }}
            />
          </a>
          <Navbar />
        </div>
      </div>
    </div>
  );
}

export default TopHeader;
