import React, { useState, useRef, useEffect } from "react";
import "./adjumpbox.scss";
import withdrawAPI from "../../API/withdrawAPI";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { baseUrl } from "../../dummy";

function Adjumpbox() {
  const [showPopup, setShowPopup] = useState(true);
  const [srcImg, setSrcImg] = useState();
  const popupRef = useRef(null);

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setShowPopup(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    try {
      const fetchGetSetting = async () => {
        const result = await withdrawAPI.getSetting();
        // console.log(result);

        if (result.status === true) {
          setSrcImg(result.data[0].popup_home_page);
        } else {
          return toast.error("Có lỗi xảy ra!", { theme: "light" });
        }
      };

      fetchGetSetting();
    } catch (error) {
      toast.error(error, { theme: "light" });
    }
  }, []);

  return (
    <div>
      {showPopup && (
        <div id="adjumpbox">
          <div className="content-adjumpbox" ref={popupRef}>
            <div className="content-main">
              {srcImg && <img src={baseUrl + srcImg} alt="" />}
            </div>
            <div className="close" onClick={togglePopup}>
              <span>X</span>
            </div>
          </div>
        </div>
      )}
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default Adjumpbox;
