import React, { useEffect, useState } from 'react';
import './xoso.scss';
import {
    bgLotte,
    iconDown,
    iconNext,
    iconPrev,
    iconUp,
    loadDot,
    mienbac,
    st1p,
    st3p,
    st5p,
    st10p,
    st15p,
    st20p,
    st30p,
} from '../../img/importImg';
import LotBetContent from './LotBetContent';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useParams } from 'react-router-dom';
import { webSocketUrl } from '../../dummy';
import { VND, getTimeLeft, getCurrentDate, formatSeconds } from '../../utils';

function XoSo() {
    const [theLoai, setTheLoai] = useState();
    const [type, setType] = useState('');
    const [socketUrl, setSocketUrl] = useState(webSocketUrl);
    const [messageNotice, setMessageNotice] = useState([]);
    const [messageNoticeSuccess, setMessageNoticeSuccess] = useState('');
    const { sendMessage, lastMessage, readyState, sendJsonMessage } = useWebSocket(socketUrl);
    const [isModalList, setIsModalList] = useState(false);
    const [selected, setSelected] = useState();
    const auth = sessionStorage.getItem('auth');
    const [tiLe, setTiLe] = useState();
    const [minCuoc, setMinCuoc] = useState();
    const [historyByUser, setHistoryByUser] = useState();
    const [history, setHistory] = useState();
    const [time, setTime] = useState(0);
    const [phien, setPhien] = useState(0);
    const [timeLeft, setTimeLeft] = useState(formatSeconds(0));
    const [moGiai, setMoGiai] = useState(false);
    const [cuocThanhCong, setIsCuocThanhCong] = useState(false);
    const [noiDungTheLoai, setNoiDungTheLoai] = useState({
        title: 'Siêu Tốc 1 Phút',
        img: st1p,
    });
    const [soTrang, setSoTrang] = useState(1);
    const [totalPage, setTotalPage] = useState(0);
    const [totalTime, setTotalTime] = useState(61);

    const now = new Date();
    const options = { timeZone: 'Asia/Ho_Chi_Minh' };
    const currentHour = now.toLocaleString('en-US', {
        hour: 'numeric',
        hour12: false,
        ...options,
    });

    const handleHistory = () => {
        sendJsonMessage({
            xoso: { getphien: true, theloai: theLoai },
        });
    };

    const handleGetHistoryByUser = () => {
        sendJsonMessage({
            xoso: {
                history: {
                    theloai: theLoai,
                    limit: 5,
                    trang: soTrang,
                    totalPage: totalPage,
                },
            },
        });
    };

    let { id } = useParams();

    useEffect(() => {
        if (!auth) {
            setTimeout(() => {
                window.location.href = '/';
            }, 1500);

            toast.error(`Vui lòng đăng nhập để trải nghiệm game!`, {
                theme: 'light',
            });

            return;
        }
    }, []);

    useEffect(() => {
        if (id) {
            setTheLoai(id);
        }

        if (id === '1') {
            setNoiDungTheLoai({ title: 'Siêu tốc 1 Phút', img: st1p });
            setTotalTime(Number(id) * 60);
        }
        if (id === '3') {
            setNoiDungTheLoai({ title: 'Siêu tốc 3 Phút', img: st3p });
            setTotalTime(Number(id) * 60);
        }
        if (id === '5') {
            setNoiDungTheLoai({ title: 'Siêu tốc 5 Phút', img: st5p });
            setTotalTime(Number(id) * 60);
        }
        if (id === '10') {
            setNoiDungTheLoai({ title: 'Siêu tốc 10 Phút', img: st10p });
            setTotalTime(Number(id) * 60);
        }
        if (id === '15') {
            setNoiDungTheLoai({ title: 'Siêu tốc 15 Phút', img: st15p });
            setTotalTime(Number(id) * 60);
        }
        if (id === '20') {
            setNoiDungTheLoai({ title: 'Siêu tốc 20 Phút', img: st20p });
            setTotalTime(Number(id) * 60);
        }
        if (id === '30') {
            setNoiDungTheLoai({ title: 'Siêu tốc 30 Phút', img: st30p });
            setTotalTime(Number(id) * 60);
        }

        if (id === 'xsmb') {
            setNoiDungTheLoai({ title: 'Xổ Số Miền Bắc', img: mienbac });
            // setTotalTime(Number(id) * 60);
        }
    }, [id]);

    useEffect(() => {
        if (id === 'xsmb') {
            const timer = setInterval(() => {
                setTimeLeft(getTimeLeft());
            }, 1000);

            return () => clearInterval(timer);
        }
    }, [id]);

    useEffect(() => {
        if (lastMessage !== null) {
            const data = JSON.parse(lastMessage.data);

            if (data.notice) {
                setMessageNotice(data);
                if (data.notice.msg === 'Đăng nhập thành công') {
                    setMessageNoticeSuccess(data.notice.msg);
                }

                if (data.notice.msg === 'Cuoc thanh cong!') {
                    setIsCuocThanhCong(true);
                    handleGetHistoryByUser();
                }
            }

            if (data.xoso) {
                if (data.xoso[theLoai]) {
                    if (data.xoso[theLoai].getphien) {
                        if (data.xoso[theLoai].getphien.data) {
                            setHistory(data.xoso[theLoai].getphien.data);
                            setSelected(data.xoso[theLoai].getphien.data[0]);
                        }
                    }

                    if (data.xoso[theLoai].time) {
                        setTimeLeft(formatSeconds(data.xoso[theLoai].time));
                        setTime(data.xoso[theLoai].time);
                    }

                    if (data.xoso[theLoai].phien) {
                        setPhien(data.xoso[theLoai].phien);
                    }

                    if (data.xoso[theLoai].history) {
                        if (data.xoso[theLoai].history.data) {
                            setHistoryByUser(data.xoso[theLoai].history.data);
                            setTotalPage(data.xoso[theLoai].history.totalPage);
                        }
                    }
                }

                if (data.xoso.tile) {
                    setTiLe(data.xoso.tile[0]);
                    setMinCuoc(data.xoso.tile[1]);
                }
            }
        }
    }, [lastMessage, theLoai]);

    const callbackFunction = (childData) => {
        setType(childData);
    };

    const callbackFunctionDatCuoc = (childData) => {
        if (id !== 'xsmb') {
            if (time < 6) {
                return toast.error(`Dưới 5s không thể đặt cược!`, {
                    theme: 'light',
                });
            }
        } else if (id === 'xsmb') {
            if (currentHour > 17) {
                return toast.error(`Đã dừng cược, vui lòng chờ kết quả!`, {
                    theme: 'light',
                });
            }
        }

        if (childData) {
            childData.theloai = id;
            sendJsonMessage({
                xoso: { cuoc: childData },
            });
        } else return;
    };

    useEffect(() => {
        const handleLogin = () => {
            sendJsonMessage({
                auth: { token: auth },
            });
        };

        handleLogin();
    }, []);

    useEffect(() => {
        const handleGetTiLe = () => {
            sendJsonMessage({
                xoso: { getTiLe: true },
            });
        };

        if (messageNoticeSuccess) {
            handleGetTiLe();
        }
    }, [messageNoticeSuccess, theLoai]);

    useEffect(() => {
        if (messageNoticeSuccess) {
            handleHistory();
            handleGetHistoryByUser();
        }
    }, [messageNoticeSuccess, theLoai]);

    useEffect(() => {
        if (messageNoticeSuccess) {
            handleGetHistoryByUser();
        }
    }, [messageNoticeSuccess, soTrang]);

    useEffect(() => {
        if (id !== 'xsmb') {
            if (time === 0 || time === totalTime || time === totalTime - 1) {
                setMoGiai(true);
            } else setMoGiai(false);
        }

        if (time === totalTime - 2) {
            handleHistory();
            handleGetHistoryByUser();
        }

        if (cuocThanhCong) {
            setIsCuocThanhCong(false);
            handleGetHistoryByUser();
            toast.success(`Cược thành công!`, {
                theme: 'light',
            });
        }
    }, [time]);

    const handleClickSelected = (valueSelected) => {
        setSelected(valueSelected);
        setIsModalList(false);
    };

    return (
        <div className="bg-game">
            <div className="content-view">
                <div className="game-container">
                    <div className="game-log-other">
                        <div className="play-wrap">
                            <div className="game-info">
                                <div className="game-name">
                                    <div className="img-box">
                                        <img
                                            src={noiDungTheLoai.img}
                                            alt=""
                                        />
                                    </div>
                                    <div>
                                        <h3>{noiDungTheLoai.title}</h3>
                                        <p>{type && type.title}</p>
                                    </div>
                                </div>
                                <div className="game-num">
                                    <div className="balls">
                                        <div className="ball">
                                            {moGiai ? (
                                                <span>
                                                    <img
                                                        src={loadDot}
                                                        alt=""
                                                    />
                                                </span>
                                            ) : (
                                                <span>{selected && String(selected.gdb).charAt(0)}</span>
                                            )}
                                        </div>
                                        <div className="ball">
                                            {moGiai ? (
                                                <span>
                                                    <img
                                                        src={loadDot}
                                                        alt=""
                                                    />
                                                </span>
                                            ) : (
                                                <span>{selected && String(selected.gdb).charAt(1)}</span>
                                            )}
                                        </div>
                                        <div className="ball">
                                            {moGiai ? (
                                                <span>
                                                    <img
                                                        src={loadDot}
                                                        alt=""
                                                    />
                                                </span>
                                            ) : (
                                                <span>{selected && String(selected.gdb).charAt(2)}</span>
                                            )}
                                        </div>
                                        <div className="ball">
                                            {moGiai ? (
                                                <span>
                                                    <img
                                                        src={loadDot}
                                                        alt=""
                                                    />
                                                </span>
                                            ) : (
                                                <span>{selected && String(selected.gdb).charAt(3)}</span>
                                            )}
                                        </div>
                                        <div className="ball">
                                            {moGiai ? (
                                                <span>
                                                    <img
                                                        src={loadDot}
                                                        alt=""
                                                    />
                                                </span>
                                            ) : (
                                                <span>{selected && String(selected.gdb).charAt(4)}</span>
                                            )}
                                        </div>
                                    </div>
                                    <span>Kết quả mở thưởng {selected?.kiSo}</span>
                                </div>
                                <div className="pc-game-time">
                                    <span>
                                        Dừng cược {getCurrentDate()} {`${id === 'xsmb' ? '' : `- ${phien}`}`}
                                    </span>
                                    {id === 'xsmb' && currentHour < 18 && (
                                        <div className="count">
                                            <div className="item">
                                                <span>{timeLeft.hours[0]}</span>
                                                <span>{timeLeft.hours[1]}</span>
                                            </div>
                                            <div className="dot"></div>
                                            <div className="item">
                                                <span>{timeLeft.minutes[0]}</span>
                                                <span>{timeLeft.minutes[1]}</span>
                                            </div>
                                            <div className="dot"></div>
                                            <div className="item">
                                                <span>{timeLeft.seconds[0]}</span>
                                                <span>{timeLeft.seconds[1]}</span>
                                            </div>
                                        </div>
                                    )}

                                    {id === 'xsmb' && currentHour >= 18 && <div className="count">Dừng cược</div>}

                                    {!moGiai && id !== 'xsmb' && (
                                        <div className="count">
                                            <div className="item">
                                                <span>{timeLeft.hours[0]}</span>
                                                <span>{timeLeft.hours[1]}</span>
                                            </div>
                                            <div className="dot"></div>
                                            <div className="item">
                                                <span>{timeLeft.minutes[0]}</span>
                                                <span>{timeLeft.minutes[1]}</span>
                                            </div>
                                            <div className="dot"></div>
                                            <div className="item">
                                                <span>{timeLeft.seconds[0]}</span>
                                                <span>{timeLeft.seconds[1]}</span>
                                            </div>
                                        </div>
                                    )}
                                    {moGiai && id !== 'xsmb' && <div className="count">Đang mở giải</div>}
                                </div>
                            </div>
                            <LotBetContent
                                parentCallback={callbackFunction}
                                parentCallbackDatCuoc={callbackFunctionDatCuoc}
                                tiLe={tiLe}
                                minCuoc={minCuoc}
                                id={id}
                            />
                        </div>
                        <div className="history-wrap">
                            <div className="game-history vncp27">
                                <div className="result-web">
                                    <img
                                        src={bgLotte}
                                        alt=""
                                    />
                                </div>
                                <div
                                    className="h-select"
                                    onClick={() => setIsModalList(!isModalList)}
                                >
                                    <div className="el-select">
                                        <div className="el-input el-input--suffix">
                                            <input
                                                type="text"
                                                readOnly="readonly"
                                                autoComplete="off"
                                                className="el-input__inner"
                                                value={selected?.kiSo || ''}
                                            />
                                            {isModalList ? (
                                                <img
                                                    src={iconUp}
                                                    alt=""
                                                    style={{ width: '20px', height: '20px' }}
                                                />
                                            ) : (
                                                <img
                                                    src={iconDown}
                                                    alt=""
                                                    style={{ width: '20px', height: '20px' }}
                                                />
                                            )}
                                        </div>

                                        {isModalList && (
                                            <div className="el-select-dropdown el-popper">
                                                <div className="el-scrollbar">
                                                    <div className="el-select-dropdown__wrap el-scrollbar__wrap">
                                                        <ul className="el-scrollbar__view el-select-dropdown__list">
                                                            {history &&
                                                                history.map((item, i) => {
                                                                    return (
                                                                        <li
                                                                            className={`el-select-dropdown__item ${
                                                                                selected?.id === item.id
                                                                                    ? 'selected'
                                                                                    : ''
                                                                            }`}
                                                                            key={i}
                                                                            onClick={() => handleClickSelected(item)}
                                                                        >
                                                                            {item.kiSo}
                                                                        </li>
                                                                    );
                                                                })}
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="popper__arrow"></div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="history-vncp pc-history-vncp">
                                    <div className="cur-item">Kỳ số {selected?.kiSo}</div>
                                    <ul className="list results1">
                                        <li>
                                            <div>Giải ĐB</div>
                                            <div>{selected?.gdb}</div>
                                        </li>
                                        <li>
                                            <div>Giải nhất</div>
                                            <div>{selected?.g1}</div>
                                        </li>
                                        <li>
                                            <div>Giải nhì</div>
                                            <div>{selected?.g2}</div>
                                        </li>
                                        <li>
                                            <div>Giải ba</div>
                                            <div>{selected?.g3}</div>
                                        </li>
                                        <li>
                                            <div>Giải tư</div>
                                            <div>{selected?.g4}</div>
                                        </li>
                                        <li>
                                            <div>Giải năm</div>
                                            <div>{selected?.g5}</div>
                                        </li>
                                        <li>
                                            <div>Giải sáu</div>
                                            <div>{selected?.g6}</div>
                                        </li>
                                        <li>
                                            <div>Giải bảy</div>
                                            <div>{selected?.g7}</div>
                                        </li>
                                        {!!selected?.g8 && (
                                            <li>
                                                <div>Giải tám</div>
                                                <div>{selected?.g8}</div>
                                            </li>
                                        )}
                                    </ul>
                                    <ul className="list results2">
                                        <li className="title">
                                            <div>Đầu</div>
                                            <div>Đuôi</div>
                                        </li>
                                        <li>
                                            <div>0</div>
                                            <div>{selected && selected.dauduoi['0']}</div>
                                        </li>
                                        <li>
                                            <div>1</div>
                                            <div>{selected && selected.dauduoi['1']}</div>
                                        </li>
                                        <li>
                                            <div>2</div>
                                            <div>{selected && selected.dauduoi['2']}</div>
                                        </li>
                                        <li>
                                            <div>3</div>
                                            <div>{selected && selected.dauduoi['3']}</div>
                                        </li>
                                        <li>
                                            <div>4</div>
                                            <div>{selected && selected.dauduoi['4']}</div>
                                        </li>
                                        <li>
                                            <div>5</div>
                                            <div>{selected && selected.dauduoi['5']}</div>
                                        </li>
                                        <li>
                                            <div>6</div>
                                            <div>{selected && selected.dauduoi['6']}</div>
                                        </li>
                                        <li>
                                            <div>7</div>
                                            <div>{selected && selected.dauduoi['7']}</div>
                                        </li>
                                        <li>
                                            <div>8</div>
                                            <div>{selected && selected.dauduoi['8']}</div>
                                        </li>
                                        <li>
                                            <div>9</div>
                                            <div>{selected && selected.dauduoi['9']}</div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="betlog-wrap">
                        <div className="bet-log">
                            <div className="table-title">
                                <div className="more"> Tiền cược：0 Vnd , Tổng thắng thua：0 Vnd </div>
                                <div className="bet-tabs d-flex">
                                    <div className="game-tabs">
                                        <div className="item-warp">
                                            <div className="item-tab item-tab-history active">Lịch sử</div>
                                            {/* <div className="item-tab item-tab-winlose">
                        Thắng thua
                      </div>
                      <div className="item-tab item-tab-pending">
                        Chưa thanh toán
                      </div> */}
                                        </div>
                                    </div>
                                    {/* <div className="b-btn">Làm mới</div> */}
                                </div>
                            </div>
                            <div
                                className="item table-name row"
                                style={{ width: '100%', marginLeft: '1px' }}
                            >
                                <div className="col-1">Loại XS</div>
                                <div className="col-1">Lượt xổ</div>
                                {/* <div className="col-1">Số thứ tự</div> */}
                                <div className="col-2">Thời gian</div>
                                <div className="col-1">Cách chơi</div>
                                <div className="col-2">Thông tin đặt cược</div>
                                <div className="col-1">Số đơn cược</div>
                                <div className="col-1">Cấp số nhân</div>
                                <div className="col-1">Tổng số tiền</div>
                                <div className="col-1">Thắng thua</div>
                                <div className="col-1">Trạng thái</div>
                            </div>
                            <div className="b-content">
                                {historyByUser ? (
                                    historyByUser.map((item, i) => {
                                        return (
                                            <div
                                                className="b-content-item"
                                                key={i}
                                            >
                                                <div className="item table-name row content-table">
                                                    <div className="col-1">
                                                        {item.theloai === 'xsmb' ? 'XSMB' : `ST ${item.theloai} Phút`}
                                                    </div>
                                                    <div className="col-1">{item.phien}</div>
                                                    {/* <div className="col-1"></div> */}
                                                    <div className="col-2">{item.date}</div>
                                                    <div className="col-1">{item.cachChoi}</div>
                                                    <div className="col-2">{item.so}</div>
                                                    <div className="col-1">1</div>
                                                    <div className="col-1">{item.amount}</div>
                                                    <div className="col-1">{VND.format(Number(item.cuoc))}</div>
                                                    <div className="col-1">{VND.format(Number(item.win))}</div>
                                                    <div className="col-1">
                                                        {item.thanhtoan == 1 ? 'Đã xử lý' : 'Chờ mở giải'}
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })
                                ) : (
                                    <div className="no-data">
                                        <p className="more">Không có thêm dữ liệu</p>
                                    </div>
                                )}
                            </div>
                            <div className="el-pagination is-background">
                                <button
                                    className="btn-prev"
                                    disabled={soTrang === 1}
                                    onClick={() => setSoTrang(soTrang - 1)}
                                >
                                    <img
                                        src={iconPrev}
                                        alt=""
                                        style={{ width: '8px' }}
                                    />
                                </button>
                                <ul className="el-pager">
                                    <li className="number active">{soTrang}</li>
                                </ul>
                                <button
                                    className="btn-next"
                                    disabled={soTrang + 1 > totalPage}
                                    onClick={() => setSoTrang(soTrang + 1)}
                                >
                                    <img
                                        src={iconNext}
                                        alt=""
                                        style={{ width: '8px' }}
                                    />
                                </button>
                            </div>
                            <div className="el-dialog__wrapper"></div>
                        </div>
                    </div>
                </div>
            </div>

            <ToastContainer
                position="top-right"
                autoClose={1000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </div>
    );
}

export default XoSo;
