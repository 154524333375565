import React, { useState, useEffect } from 'react';
import NavbarXS from './NavbarXS/NavbarXS';
import NavbarCasino from './NavbarCasino/NavbarCasino';
import gameAPI from '../../../API/gameAPI';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import withdrawAPI from '../../../API/withdrawAPI';

function Navbar() {
    const [dataCasino, setDataCasino] = useState();
    const [dataGameSlot, setDataGameSlot] = useState();
    const [dataBanCa, setDataBanCa] = useState();
    const [dataTheThao, setDataTheThao] = useState();
    const [dataGameBai, setDataGameBai] = useState();
    const [dataDaGa, setDataDaGa] = useState();
    const [dataEsport, setDataEsport] = useState();
    const [dataOther, setDataOther] = useState();
    const [settings, setSettings] = useState();

    useEffect(() => {
        try {
            const fetchGetSetting = async () => {
                const result = await withdrawAPI.getSetting();
                // console.log(result);

                if (result.status === true) {
                    setSettings(result.data[0]);
                } else {
                    return toast.error('Có lỗi xảy ra!', { theme: 'light' });
                }
            };

            fetchGetSetting();
        } catch (error) {
            toast.error('Có lỗi xảy ra', { theme: 'light' });
        }
    }, []);

    useEffect(() => {
        try {
            const fetchGetListGame = async () => {
                const result = await gameAPI.getlistGame();

                if (result.status === true) {
                    const dataLC = result.result['2'] || [];
                    const dataSL = result.result['1'] || [];
                    const dataFH = result.result['8'] || [];
                    const dataCB = result.result.CB || [];
                    const dataES = result.result.ES || [];
                    const dataOT = result.result.OT || [];
                    const dataDK = result.result.OT || [];
                    const dataSB = result.result['3'] || [];
                    const dataLK = result.result['5'] || [];

                    setDataCasino(dataLC);
                    setDataGameSlot(dataSL);
                    setDataBanCa(dataFH);
                    setDataTheThao(dataSB);
                    setDataGameBai(dataCB);
                    setDataEsport(dataES);
                    setDataOther(dataOT.concat(dataLK));
                    setDataDaGa(dataDK);
                }
            };

            fetchGetListGame();
        } catch (error) {
            return toast.error('Có lỗi xảy ra!', { theme: 'light' });
        }
    }, []);

    return (
        <nav className="d-nav">
            <ul>
                <li className="home">
                    <a
                        href="/"
                        className="smooth"
                    >
                        <span>Trang chủ</span>
                    </a>
                </li>
                <li className="lottery">
                    <a
                        href="/"
                        className="smooth"
                    >
                        <span>Xổ số</span>
                    </a>
                    <NavbarXS />
                </li>
                <li className="live">
                    <a
                        href="/"
                        className="smooth"
                    >
                        <span>Casino</span>
                    </a>
                    <NavbarCasino data={dataCasino} />
                </li>
                <li className="slots">
                    <a
                        href="/"
                        className="smooth"
                    >
                        <span>Game slot</span>
                    </a>
                    <NavbarCasino data={dataGameSlot} />
                </li>
                <li className="by">
                    <a
                        href="/"
                        className="smooth"
                    >
                        <span>Bắn cá</span>
                    </a>
                    <NavbarCasino
                        data={dataBanCa}
                        center={true}
                    />
                </li>
                <li className="hot sport">
                    <a
                        href="/"
                        className="smooth"
                    >
                        <span>Thể thao</span>
                    </a>
                    <NavbarCasino data={dataTheThao} />
                </li>
                <li className="qp">
                    <a
                        href="/"
                        className="smooth"
                    >
                        <span>Game bài</span>
                    </a>
                    <NavbarCasino
                        data={dataGameBai}
                        center={true}
                    />
                </li>
                <li className="dj">
                    <a
                        href="/"
                        className="smooth"
                    >
                        <span>Đá gà</span>
                    </a>
                    <NavbarCasino
                        data={dataDaGa}
                        center={true}
                    />
                </li>
                <li className="es">
                    <a
                        href="/"
                        className="smooth"
                    >
                        <span>Esport Game</span>
                    </a>
                    <NavbarCasino
                        data={dataEsport}
                        center={true}
                    />
                </li>
                <li className="dj">
                    <a
                        href="/"
                        className="smooth"
                    >
                        <span>Game khác</span>
                    </a>
                    <NavbarCasino
                        data={dataOther}
                        center={true}
                    />
                </li>
                <li className="hot activity">
                    <Link
                        to="/promotion"
                        className="smooth"
                    >
                        <span>Khuyến mãi</span>
                    </Link>
                </li>
                <li className="servicer">
                    <Link
                        to="/support"
                        className="smooth"
                    >
                        <span>Chat trực tuyến</span>
                    </Link>
                </li>
            </ul>
        </nav>
    );
}

export default Navbar;
